<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="rating"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <div class="columns">
      <div class="column">
        <b-field label="History of falls" horizontal class="label-long">
          <EnumDropDown v-model="form.fallsHistory" :data="fallsHistoryLookup" :disabled="readonly" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Mobility" horizontal class="label-long">
          <EnumDropDown v-model="form.mobility" :data="mobilityLookup" :disabled="readonly" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Gait" horizontal class="label-long">
          <EnumDropDown v-model="form.gait" :data="gaitLookup" :disabled="readonly" />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column label-long">
        <b-field label="Sensory Deficit" horizontal>
          <b-checkbox v-model="form.sensoryBalance" :disabled="readonly">Balance</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.sensoryHearing" :disabled="readonly">Hearing</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.sensorySight" :disabled="readonly">Sight</b-checkbox>
        </b-field>
      </div>
      <div class="column label-long">
        <b-field label="Medication Type" horizontal>
          <b-checkbox v-model="form.mentalIllness" :disabled="readonly">Mential Illness &amp; Drug / Alcohol Withdrawal</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.bloodPressue" :disabled="readonly">Blood Pressure / Water Tablets</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.sedatives" :disabled="readonly">Sedatives / Analgesia / Night Sedation </b-checkbox>
        </b-field>
      </div>
      <div class="column label-long">
        <b-field label="Medical History" horizontal>
          <b-checkbox v-model="form.cardiovascular" :disabled="readonly">Cardiovascular disease / Diabetes</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.confusion" :disabled="readonly">Confusion</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.fits" :disabled="readonly">Fits</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.incontinent" :disabled="readonly">Incontinent</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.noncooperative" :disabled="readonly">Inability to cooperate</b-checkbox>
        </b-field>
      </div>
    </div>
  </AssessmentForm>
</template>

<script>
// //////////////////////
// Required for all Assessments
import AssessmentForm from "@/views/assessments/AssessmentForm";
import assessmentMixin from "@/mixins/assessmentMixin";

import AssessmentType from "@/enums/assessmentType";
import Gender from "@/enums/gender";
import Risk from "@/enums/risk";
// //////////////////////

import FallsHistory from "@/enums/fallsHistory";
import Mobility from "@/enums/mobility";
import Gait from "@/enums/gait";

import EnumDropDown from "@/components/EnumDropDown";

export default {
  components: { AssessmentForm, EnumDropDown },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.Cannard,
      form: this.getClearFormObject(),

      fallsHistoryLookup: FallsHistory.Lookup,
      gaitLookup: Gait.Lookup,
      mobilityLookup: Mobility.CanardLookup
    };
  },

  computed: {
    ageScore() {
      const age = this.form.age || 0;
      if (age < 60) return 0;
      if (age < 71) return 1;
      if (age < 81) return 2;
      return 1;
    },
    sensoryScore() {
      return (this.form.sensorySight ? 2 : 0) + (this.form.sensoryHearing ? 1 : 0) + (this.form.sensoryBalance ? 2 : 0);
    },
    medicationScore() {
      return (this.form.mentalIllness ? 1 : 0) + (this.form.bloodPressue ? 1 : 0) + (this.form.sedatives ? 1 : 0);
    },
    medicalHistoryScore() {
      return (
        (this.form.cardiovascular ? 1 : 0) +
        (this.form.confusion ? 1 : 0) +
        (this.form.fits ? 1 : 0) +
        (this.form.incontinent ? 1 : 0) +
        (this.form.noncooperative ? 1 : 0)
      );
    },
    score() {
      var score = this.form.gender === Gender.Enum.Male ? 1 : 2;
      score += this.ageScore;
      score += this.form.fallsHistory || 0;
      score += this.sensoryScore + this.medicationScore + this.medicalHistoryScore;
      score += Mobility.CanardScore[this.form.mobility || 0];
      score += Gait.Score[this.form.gait || 0];
      return score;
    },
    rating() {
      const score = this.score;
      if (score < 8) return Risk.Enum.LowRisk;
      if (score < 13) return Risk.Enum.MediumRisk;
      return Risk.Enum.HighRisk;
    }
  },

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: null,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        fallsHistory: null,

        mobility: null,

        sensorySight: false,
        sensoryHearing: false,
        sensoryBalance: false,

        mentalIllness: false,
        bloodPressue: false,
        sedatives: false,

        cardiovascular: false,
        confusion: false,
        fits: false,
        incontinent: false,
        noncooperative: false,

        gait: null
      };
    }
  }
};
</script>
