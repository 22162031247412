const GaitEnum = {
  Steady: 0,
  Hesitant: 1,
  Poor: 2,
  Unsteady: 3
};

export default {
  Enum: GaitEnum,
  Lookup: [
    { id: GaitEnum.Steady, value: "Steady" },
    { id: GaitEnum.Hesitant, value: "Hesitant in initiating movement" },
    { id: GaitEnum.Poor, value: "Poor transfer" },
    { id: GaitEnum.Unsteady, value: "Unsteady" }
  ],
  Score: [1, 1, 3, 3]
};
