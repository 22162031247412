const FallsHistoryEnum = {
  None: 0,
  AtHome: 1,
  InWardUnit: 2
};

export default {
  Enum: FallsHistoryEnum,
  Lookup: [
    { id: FallsHistoryEnum.None, value: "None" },
    { id: FallsHistoryEnum.AtHome, value: "At Home" },
    { id: FallsHistoryEnum.InWardUnit, value: "In Ward / Unit" }
  ]
};
