const MobilityEnum = {
  Fully: 0,
  Restless: 1,
  Apathetic: 2,
  Restricted: 3,
  Bedbound: 4,
  ChairBound: 5,
  UserAids: 6 // Cannard Only
};

export default {
  Enum: MobilityEnum,
  Lookup: [
    { id: MobilityEnum.Fully, value: "Fully mobile" },
    { id: MobilityEnum.Restless, value: "Restless / Fidgety" },
    { id: MobilityEnum.Apathetic, value: "Apathetic (sedated/depressed/reluctant to move)" },
    { id: MobilityEnum.Restricted, value: "Restricted (restricted by severe pain or disease)" },
    { id: MobilityEnum.Bedbound, value: "Bedbound (unconscious/unable to change position/traction)" },
    { id: MobilityEnum.ChairBound, value: "Chair bound (unable to leave chair without assistance)" }
  ],
  CanardLookup: [
    { id: MobilityEnum.Fully, value: "Fully mobile" },
    { id: MobilityEnum.UserAids, value: "Uses Aids" },
    { id: MobilityEnum.Restricted, value: "Restricted (restricted by severe pain or disease)" },
    { id: MobilityEnum.Bedbound, value: "Bed/Chair bound / Wheelchair" }
  ],
  CanardScore: [1, 0, 0, 3, 1, 1, 2]
};
